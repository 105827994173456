<template>
  <div class="home newsphoto">
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
      <van-cell class="content" v-for="(item,index) in tableListData" :key="index" @click="rowGetRouter(item)">
        <div  class="contenLeft">
          <img :src="item.image" alt="">
        </div>
        <div  class="contenRight">
          <p>{{item.title}}</p>
          <strong>{{item.create_time}}</strong>
          <span class="flexDispay">
              <img :src="item.media_logo" alt="" class="imgGig">
              <span class="bomTitle">{{item.media_name}}</span>
          </span>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>

import {
  journalismList,
} from "@/api/index";

export default {
  components: {
  },
  data(){
    return{
      tableListData:[],
      loading:false,
      finished:false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total:0,
    }
  },
  created(){
    this.initTable();
  },
  methods:{
    initTable(){
      let datas = {
        page:this.page,
        type:2,
        categoryId:Number(this.$route.query.id)
      }
      journalismList(datas).then(res=>{
        this.total = res.data.newsList.total;
        this.loading = false;
        if (res.data.newsList.list == null || res.data.newsList.list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }

        // 将新数据与老数据进行合并
        this.tableListData = this.tableListData.concat(res.data.newsList.list);
       
       //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.tableListData.length >= this.total) {
          this.finished = true;
        }
      })
    },
    onLoad() {
      this.page++;
      this.initTable();
    },
    rowGetRouter(row){
      this.$router.push({
        name:'GraphicDetails',
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.newsphoto{
  background:#fff;
  padding-top: 10px;
}
.header{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftHead{
  flex:7;
  border: 1px solid #f00;
}
.rightHead{
  flex:1;
  img{
    width: 26px;
  }
}

.content{
  ::v-deep{
    .van-cell__value{
      border-radius: 7px;
      display: flex;
      height: 85px;
      padding: 10px;
      background: #fff;
      overflow: hidden;
    }
    
  }
}
::v-deep{
  .van-cell{
    line-height: 17px;
    padding:0;
  }
}
.contenLeft{
  flex:3;
  img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.contenRight{
  flex:6;
  margin: 0 auto;
  position: relative;
  p{
    margin: 0;
    text-align: left;
    padding: 0 0 0 9px;
    font-size: 14px;
    font-weight:700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* 超出n行这里写n */
    -webkit-box-orient: vertical;
  }
  strong{
    display: flex;
    font-size: 12px;
    font-weight: normal;
    color: #acacac;
    padding: 14px 0 0 10px;
  }
  ul{
    display: flex;
    justify-content: left;
    font-size: 13px;
    color: #838383;
    padding: 5px 0 0 5px;
    li{
      flex:1
    }
  }
  li{
    list-style: none;
    font-size: 13px;
    color: #838383;
    text-align: left;
    &:last-child{
      padding-left: 5px;
    }
  }
}
.imgGig{
    position: relative;
    top: 2px;
    padding-right: 4px;
    width: 14px;
    height: 14px;
}
.bomTitle{
    font-size: 14px;
    color: #A7A7A7;
}
.flexDispay{
    text-align: left;
    display: inherit;
    padding: 0 0 0 8px;
    position: absolute;
    bottom: 0px;
    
}

</style>
